import React from 'react'
import ReactDOM from 'react-dom/client'
import 'dayjs/locale/fr'

import './resources/locale/locale'
import { theme } from './theme'
import { Store } from './state'

import { StoreProvider } from 'easy-peasy'
import { MantineProvider } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'

import { Router } from './layout/router'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <StoreProvider store={Store}>
    <MantineProvider theme={theme} withGlobalStyles>
      <ModalsProvider>
        <Router />
      </ModalsProvider>
    </MantineProvider>
  </StoreProvider>,
)
