import { Set } from '../types'
import { set } from '../utils'

import { AudioBook, AudioTheme, AudioBackground } from './audio'

export type Days =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'

export interface Localized {
  fr: string
  en: string
}

export interface Room {
  _id: string
  name?: string
  localizedName?: Localized
  localizedDescription?: Localized
}

export interface Cocoon {
  _id: string
  version: number
  localizedName?: Localized
  localizedDescription?: Localized
  room: {
    _id: string
  }
}

export interface Space {
  _id: string
  name: string
  created_at: string
  localizedName: Localized
  code: string
  codes: { code: string; expiration: string | null }[]
  description: string
  emails: { email: string }[]
  localizedDescription: Localized
  rooms: Room[]
  cocoons: Cocoon[]
  options?: {
    napDelay?: number
    napsPerDay?: number
    napDurations?: {
      duration10?: boolean
      duration15?: boolean
      duration20?: boolean
      duration25?: boolean
    }
    napDays: Record<Days, { startHour?: number; endHour?: number }>
  }
  subscription?: {
    start?: string
    end?: string
    licenses?: number
    premium?: boolean
    disabled?: {
      audio?: boolean
      profile?: boolean
      booking?: boolean
    }
    audio: {
      themes: AudioTheme[]
      backgrounds: AudioBackground[]
      books: AudioBook[]
    }
  }
}

export type SpaceList = Pick<Space, '_id' | 'name' | 'code'> & {
  status: 'pending' | 'active' | 'expiring' | 'expired' | 'inactive'
  subscription?: Pick<
    NonNullable<Space['subscription']>,
    'start' | 'end' | 'premium' | 'licenses'
  >
}

export type SpaceInfo = Pick<
  Space,
  | '_id'
  | 'localizedName'
  | 'localizedDescription'
  | 'codes'
  | 'code'
  | 'emails'
  | 'created_at'
> & {
  subscription?: Pick<
    NonNullable<Space['subscription']>,
    'start' | 'end' | 'premium' | 'disabled' | 'licenses'
  >
}

export type SpaceOptions = Pick<Space, '_id' | 'options'>

export type SpaceCocoons = Pick<
  Space,
  '_id' | 'cocoons' | 'rooms' | 'localizedName'
>

export type SpaceMaintenance = {
  _id: string
  maintenances: {
    target: string | null
    start: string
    end: string
  }[]
  cocoons: { _id: string; name: string; room: { _id: string } }[]
  rooms: { _id: string; name: string }[]
}

interface ModelSpace {
  // State
  list: SpaceList[]

  // Actions
  setList: Set<this, 'list'>
}

export const modelSpace: ModelSpace = {
  // State
  list: [],

  // Actions
  setList: set('list'),
}
