import React from 'react'
import { RouteComponentProps } from 'wouter'

import { buildComponent } from '../../../components/factory'

import * as UI from '@mantine/core'
import * as Components from './components'

export const ScreenConnectedSpacesEdit = buildComponent<
  RouteComponentProps<{ space: string }>
>().withRender(({ props }) => (
  <React.Fragment>
    <Components.Title space={props.params.space} />

    <UI.Stack sx={{ maxWidth: 1280, margin: '2rem auto 32rem' }} spacing={32}>
      <UI.Card shadow="sm" pb={32} px={24}>
        <Components.SectionMain space={props.params.space} />
      </UI.Card>

      <UI.Card shadow="sm" pb={32} px={24}>
        <Components.SectionBooking space={props.params.space} />
      </UI.Card>

      <UI.Card shadow="sm" pb={32} px={24}>
        <Components.SectionCocoon space={props.params.space} />
      </UI.Card>

      <UI.Card shadow="sm" pb={32} px={24}>
        <Components.SectionMaintenance space={props.params.space} />
      </UI.Card>
    </UI.Stack>
  </React.Fragment>
))
