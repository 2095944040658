import React, { useEffect, useMemo, useState } from 'react'
import { get, set, del } from 'idb-keyval'
import { marked } from 'marked'
import { NodeHtmlMarkdown } from 'node-html-markdown'

import { serviceModals } from '../../../../services/modals'
import {
  getDocumentReference,
  upsertDocument,
} from '../../../../helpers/firestore'

import { buildComponent } from '../../../../components/factory'
import { useStoreState } from '../../../../hooks/state'

import * as UI from '@mantine/core'
import * as RTE from '@mantine/rte'
import * as Icons from '../../../../components/icons'

export const RichContent = buildComponent<{
  language: 'fr' | 'en'
  state: 'legalNotices' | 'privacyPolicy'
  draft: 'legal-notices' | 'privacy-policy'
  confirmation: {
    title: string
    content: string
  }
  header: React.ReactNode
}>()
  .withLifecycle(({ props }) => {
    const data = useStoreState((store) => store.dynamicContent[props.state])
    const [draft, setDraft] = useState<string | null>(null)
    const [key, setKey] = useState('')

    useEffect(() => {
      get('content:' + props.draft + ':' + props.language)
        .then(setDraft)
        .then(() => setKey(props.language))
    }, [props.language, props.draft])

    const value = useMemo(
      () => marked.parse(draft ?? data?.[props.language] ?? ''),
      [draft, data, props.language],
    )
    const needPublish =
      draft?.replace(/<!-- -->/g, '').trim() !==
      data?.[props.language].replace(/<!-- -->/g, '').trim()

    const onChange = async (html: string) => {
      const markdown =
        NodeHtmlMarkdown.translate(html).replace(/ \n/g, ' <!-- -->\n') +
        '<!-- -->'
      setDraft(markdown)
    }
    const onPublish = async () => {
      await serviceModals.confirmAction(
        props.confirmation.title,
        props.confirmation.content,
      )

      await upsertDocument(
        getDocumentReference('dynamic-content', props.state),
        { [props.language]: draft?.replace(/<!-- -->/g, '').trim() ?? null },
      )
    }
    const onReload = async () => {
      await serviceModals.confirmAction(
        'Charger les données publiées',
        'Cette action supprimera les données locales de votre ordinateur, et les remplacera par les données actuellement publiées. Est-ce bien ce que vous souhaitez ?',
      )
      await del('content:' + props.draft + ':' + props.language)
      setDraft(null)
    }

    useEffect(() => {
      if (draft && needPublish) {
        set('content:' + props.draft + ':' + props.language, draft)
      }
      if (!draft || !needPublish) {
        del('content:' + props.draft + ':' + props.language)
      }
    }, [draft, needPublish, props.draft, props.language])

    return { value, onChange, needPublish, onPublish, onReload, key }
  })
  .withRender(({ props, lifecycle }) => (
    <UI.Card
      shadow="sm"
      mb={32}
      sx={{ maxWidth: 1280, margin: '2rem auto', width: '100%' }}
    >
      <UI.Group mb={24}>
        <UI.Text style={{ flex: 1, width: '100%' }}>{props.header}</UI.Text>
        <UI.Button
          px={32}
          onClick={lifecycle.onReload}
          disabled={!lifecycle.needPublish}
          ml={128}
          sx={{ minHeight: 32 }}
          color="gray"
          leftIcon={<Icons.FontAwesomeIcon icon={Icons.Solid.faSync} />}
        >
          Réinitialiser
        </UI.Button>
        <UI.Button
          px={32}
          disabled={!lifecycle.needPublish}
          onClick={lifecycle.onPublish}
          sx={{ minHeight: 32 }}
          leftIcon={<Icons.FontAwesomeIcon icon={Icons.Solid.faUpload} />}
        >
          Publier
        </UI.Button>
      </UI.Group>

      <RTE.RichTextEditor
        key={lifecycle.key}
        mt={16}
        sx={{
          '& .quill, & .ql-editor': {
            minHeight: '64rem',
          },
          '& .mantine-RichTextEditor-toolbar': {
            position: 'fixed',
            top: 'auto',
            bottom: '0',
            right: '0',
            left: '0',
            borderTop: '1px solid rgba(0, 0, 0, 0.2)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
        value={lifecycle.value}
        onChange={lifecycle.onChange}
        controls={[
          ['bold', 'italic', 'underline'],
          ['h1', 'h2', 'h3', 'h4'],
          ['unorderedList', 'orderedList', 'blockquote'],
          ['link'],
          ['sup', 'sub'],
        ]}
      />
    </UI.Card>
  ))
