import React, { useEffect, useState } from 'react'
import deepmerge from 'deepmerge'

import { Localized, SpaceCocoons } from '../../../../state/models/space'
import { graphql } from '../../../../services/graphql'
import { serviceModals } from '../../../../services/modals'

import { NapCocoon } from '../../../../resources/icons/nap-cocoon'

import { useSynchronizeData } from '../../../../hooks/use-synchronize-data'

import { buildComponent } from '../../../../components/factory'

import * as UI from '@mantine/core'
import * as Icons from '../../../../components/icons'
import * as Semantics from '../../../../components/semantics'

const SelectRoom = buildComponent<
  Partial<SpaceCocoons> & {
    room?: string
    setRoom: (r: string | null) => void
    addRoom: () => void
  }
>().withRender(({ props }) => {
  if (!props.rooms?.length) {
    return (
      <UI.Group mx="auto">
        <UI.Button
          style={{ minHeight: '4rem' }}
          mt={-6}
          mr={-64}
          onClick={props.addRoom}
        >
          Ajouter une salle
        </UI.Button>
      </UI.Group>
    )
  }

  const sanitizedValue = props.rooms?.find((r) => r._id === props.room)?._id
  const RoomSelector = props.rooms?.length > 4 ? UI.Select : UI.SegmentedControl

  return (
    <UI.Group mx="auto">
      <RoomSelector
        value={sanitizedValue}
        onChange={(room) => props.setRoom(room)}
        data={
          props.rooms?.map((room) => ({
            label: room?.name ?? 'Salle sans nom',
            value: room?._id ?? '',
          })) ?? ['---']
        }
        styles={() => ({
          wrapper: {
            width: 320,
          },
          label: {
            padding: '1rem 4rem',
          },
        })}
      />

      <UI.Button
        style={{ minHeight: '4rem' }}
        mt={-6}
        mr={-64}
        onClick={props.addRoom}
      >
        Ajouter une salle
      </UI.Button>
    </UI.Group>
  )
})

const EditRoom = buildComponent<
  SpaceCocoons['rooms'][number] & {
    update: (
      room: Partial<SpaceCocoons['rooms'][number]> & { _id: string },
    ) => void
    remove: (room: string) => void
  }
>()
  .withLifecycle(({ props }) => ({
    updateName: (localizedName: Localized) =>
      props._id && props.update({ localizedName, _id: props._id }),
    updateDescription: (localizedDescription: Localized) =>
      props._id && props.update({ localizedDescription, _id: props._id }),
  }))
  .withRender(({ props, lifecycle }) => (
    <UI.Group style={{ width: '100%' }}>
      <UI.Input.Wrapper style={{ flex: 1 }} label="Nom de la salle">
        <Semantics.LocalizedInput
          value={props.localizedName}
          onChange={lifecycle.updateName}
        />
      </UI.Input.Wrapper>
      <UI.Input.Wrapper style={{ flex: 1 }} label="Description de la salle">
        <Semantics.LocalizedInput
          value={props.localizedDescription}
          onChange={lifecycle.updateDescription}
        />
      </UI.Input.Wrapper>
      <UI.ActionIcon
        size="lg"
        component="button"
        onClick={() => props.remove(props._id)}
      >
        <UI.Text color="red">
          <Icons.FontAwesomeIcon
            color="currentColor"
            icon={Icons.Solid.faTrash}
          />
        </UI.Text>
      </UI.ActionIcon>
    </UI.Group>
  ))

const EditCocoon = buildComponent<
  SpaceCocoons['cocoons'][number] & {
    update: (
      cocoon: Partial<SpaceCocoons['cocoons'][number]> & { _id: string },
    ) => void
    remove: (cocoon: string) => void
  }
>()
  .withLifecycle(({ props }) => ({
    updateName: (localizedName: Localized) =>
      props.update({ localizedName, _id: props._id }),
    updateDescription: (localizedDescription: Localized) =>
      props.update({ localizedDescription, _id: props._id }),
    updateVersion: (version: number) =>
      props.update({ version, _id: props._id }),
  }))
  .withRender(({ props, lifecycle }) => (
    <UI.Card withBorder style={{ minWidth: '64rem', height: '37rem' }} py={24}>
      <UI.Group align="start" spacing={32}>
        <UI.Input.Wrapper label="Version">
          <UI.Menu
            withinPortal
            styles={{
              dropdown: {
                '& > div': { display: 'flex', flexDirection: 'row' },
              },
            }}
            position="right"
          >
            <UI.Menu.Target>
              <UI.Card
                mt={16}
                mx="auto"
                p={8}
                withBorder
                sx={{ width: '8rem', height: '8rem', cursor: 'pointer' }}
              >
                <NapCocoon height="6rem" version={props.version} />
              </UI.Card>
            </UI.Menu.Target>
            <UI.Menu.Dropdown>
              {[1, 2, 3, 4].map((version) => (
                <UI.Menu.Item
                  key={version}
                  onClick={() => lifecycle.updateVersion(version)}
                >
                  <NapCocoon version={version} height="6rem" />
                </UI.Menu.Item>
              ))}
            </UI.Menu.Dropdown>
          </UI.Menu>
        </UI.Input.Wrapper>
        <UI.Input.Wrapper style={{ flex: 1 }} label="Nom du cocon">
          <Semantics.LocalizedInput
            value={props.localizedName}
            onChange={lifecycle.updateName}
          />
        </UI.Input.Wrapper>
        <UI.ActionIcon
          mr={-8}
          ml={-64}
          mt={-16}
          size="lg"
          component="button"
          onClick={() => props.remove(props._id)}
        >
          <UI.Text color="red">
            <Icons.FontAwesomeIcon
              color="currentColor"
              icon={Icons.Solid.faTrash}
            />
          </UI.Text>
        </UI.ActionIcon>
      </UI.Group>
      <UI.Input.Wrapper mt={16} label="Description du cocon">
        <Semantics.LocalizedInput
          value={props.localizedDescription}
          onChange={lifecycle.updateDescription}
        />
      </UI.Input.Wrapper>
    </UI.Card>
  ))

const ListCocoons = buildComponent<
  Partial<SpaceCocoons> & {
    room?: string
    addCocoon: () => void
    updateCocoon: (
      cocoon: Partial<SpaceCocoons['cocoons'][number]> & { _id: string },
    ) => void
    removeCocoon: (cocoon: string) => void
  }
>().withRender(({ props }) => (
  <UI.Input.Wrapper label="Cocons de la salle">
    <UI.Box
      mt={16}
      style={{
        display: 'grid',
        gap: '2rem',
        gridTemplateColumns: 'repeat(auto-fit, minmax(64rem, 1fr))',
      }}
    >
      {props.cocoons
        ?.filter((cocoon) => cocoon.room._id === props.room)
        .map((cocoon) => (
          <EditCocoon
            key={cocoon?._id}
            {...cocoon}
            update={props.updateCocoon}
            remove={props.removeCocoon}
          />
        ))}
      <UI.UnstyledButton onClick={props.addCocoon}>
        <UI.Card
          withBorder
          sx={(theme) => ({
            minWidth: '64rem',
            height: '37rem',
            backgroundColor: theme.fn.rgba(
              theme.colors[theme.primaryColor][0],
              0.5,
            ),
            ':hover': {
              backgroundColor: theme.colors[theme.primaryColor][0],
            },
          })}
          py={32}
        >
          <UI.Stack
            mx="auto"
            align="center"
            justify="center"
            sx={{ height: '100%' }}
          >
            <Icons.FontAwesomeIcon size="lg" icon={Icons.Solid.faPlus} />
            <UI.Text weight="bold">Ajouter un cocon</UI.Text>
          </UI.Stack>
        </UI.Card>
      </UI.UnstyledButton>
    </UI.Box>
  </UI.Input.Wrapper>
))

export const SectionCocoon = buildComponent<{ space: string }>()
  .withLifecycle(({ props }) => {
    const {
      loading,
      data: space,
      update,
    } = useSynchronizeData(
      graphql.queries.getSpaceCocoons,
      async () => graphql.queries.getSpaceCocoons(props.space),
      props.space,
    )

    const [room, setRoom] = useState<string | null>(null)
    const selectedRoom = space?.rooms.find((r) => r._id === room)
    const firstRoom = space?.rooms?.[0]?._id

    useEffect(() => {
      if (!selectedRoom && firstRoom) {
        setRoom(firstRoom)
      }
    }, [firstRoom, selectedRoom])

    const addRoom = () => {
      update(
        {},
        async () => {
          await graphql.mutations.addRoom(props.space)
        },
        0,
      )
    }

    const removeRoom = (r: string) => {
      update(
        {},
        async () => {
          try {
            await serviceModals.confirmDeletion(
              'Supprimer une salle',
              'Voulez-vous vraiment supprimer cette salle ?',
            )
            await graphql.mutations.deleteRoom(r)
          } catch (err) {}
        },
        0,
      )
    }

    const updateRoom = (
      r: Partial<SpaceCocoons['rooms'][number]> & { _id: string },
    ) => {
      update(
        {
          rooms: space?.rooms.map((prev) =>
            prev._id === r._id ? deepmerge(prev, r) : prev,
          ),
        },
        async () => {
          await graphql.mutations.updateRoom(r)
        },
      )
    }

    const addCocoon = () => {
      if (room) {
        update(
          {},
          async () => {
            await graphql.mutations.addCocoon(props.space, room)
          },
          0,
        )
      }
    }

    const updateCocoon = async (
      c: Partial<SpaceCocoons['cocoons'][number]> & { _id: string },
    ) => {
      update(
        {
          cocoons: space?.cocoons.map((prev) =>
            prev._id === c._id ? deepmerge(prev, c) : prev,
          ),
        },
        async () => {
          await graphql.mutations.updateCocoon(c)
        },
      )
    }

    const removeCocoon = (c: string) => {
      update(
        {},
        async () => {
          try {
            await serviceModals.confirmDeletion(
              'Supprimer un cocon',
              'Voulez-vous vraiment supprimer ce cocon ?',
            )
            await graphql.mutations.deleteCocoon(c)
          } catch (err) {}
        },
        0,
      )
    }

    return {
      space,
      update,
      loading,
      room,
      selectedRoom,
      setRoom,
      addRoom,
      addCocoon,
      updateRoom,
      updateCocoon,
      removeRoom,
      removeCocoon,
    }
  })
  .withRender(({ lifecycle }) => (
    <UI.Input.Wrapper
      label={
        <b style={{ fontSize: '2rem' }}>
          Salles et cocons de l'espace Nom de l'Espace
        </b>
      }
    >
      <UI.Stack mt={16}>
        <SelectRoom
          {...lifecycle.space}
          room={lifecycle.room ?? undefined}
          setRoom={lifecycle.setRoom}
          addRoom={lifecycle.addRoom}
        />
        {lifecycle.selectedRoom && (
          <>
            <EditRoom
              {...lifecycle.selectedRoom}
              update={lifecycle.updateRoom}
              remove={lifecycle.removeRoom}
            />
            <ListCocoons
              {...lifecycle.space}
              room={lifecycle.room ?? undefined}
              addCocoon={lifecycle.addCocoon}
              updateCocoon={lifecycle.updateCocoon}
              removeCocoon={lifecycle.removeCocoon}
            />
          </>
        )}
      </UI.Stack>

      {lifecycle.loading && (
        <UI.Loader
          sx={{ position: 'absolute', top: '1rem', right: '1rem' }}
          size="xs"
        />
      )}
    </UI.Input.Wrapper>
  ))
