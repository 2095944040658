import React from 'react'

import { SpaceOptions } from '../../../../state/models/space'
import { graphql } from '../../../../services/graphql'

import { NapClock } from '../../../../resources/icons'

import {
  useSynchronizeData,
  DeepPartial,
} from '../../../../hooks/use-synchronize-data'

import { buildComponent } from '../../../../components/factory'

import * as UI from '@mantine/core'

const days = [
  '',
  'Lundi',
  'Mardi',
  'Mercredi',
  'Jeudi',
  'Vendredi',
  'Samedi',
  'Dimanche',
]
const dayKeys = [
  'monday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
] as const

const SelectDurations = buildComponent<
  Partial<SpaceOptions> & {
    update: (options: DeepPartial<SpaceOptions>, timeout: number) => void
  }
>()
  .withLifecycle(({ props }) => ({
    toggleDuration: (duration: 10 | 15 | 20 | 25) => () => {
      props.update(
        {
          options: {
            napDurations: {
              [`duration${duration}`]:
                !props.options?.napDurations?.[`duration${duration}`],
            },
          },
        },
        0,
      )
    },
  }))
  .withRender(({ props, lifecycle }) => (
    <UI.Input.Wrapper
      label={
        <b style={{ fontSize: '2rem' }}>
          Durées de siestes disponibles dans l'espace Nom de l'Espace
        </b>
      }
    >
      <UI.Group mt={16} position="center">
        {([10, 15, 20, 25] as const).map((duration) => {
          const active = !!props.options?.napDurations?.[`duration${duration}`]

          return (
            <UI.UnstyledButton
              key={duration}
              onClick={lifecycle.toggleDuration(duration)}
            >
              <UI.Card
                withBorder
                sx={(theme) => ({
                  opacity: active ? 1 : 0.5,
                  backgroundColor: active
                    ? theme.colors.darkblue[5]
                    : 'transparent',
                  ':hover': {
                    backgroundColor: active
                      ? theme.colors.darkblue[4]
                      : 'rgba(0,0,0,0.05)',
                  },
                })}
              >
                <UI.Stack align="center" justify="center" spacing={8}>
                  <NapClock
                    width="8rem"
                    height="8rem"
                    duration={duration}
                    active={active}
                  />
                  <UI.Text
                    weight="bold"
                    color={active ? 'white' : 'darkblue.5'}
                  >
                    {duration} minutes
                  </UI.Text>
                </UI.Stack>
              </UI.Card>
            </UI.UnstyledButton>
          )
        })}
      </UI.Group>
    </UI.Input.Wrapper>
  ))

const SelectMaxNaps = buildComponent<
  Partial<SpaceOptions> & {
    update: (options: DeepPartial<SpaceOptions>) => void
  }
>().withRender(({ props }) => (
  <UI.NumberInput
    label={
      <b style={{ fontSize: '2rem' }}>Nombre de siestes autorisées par jour</b>
    }
    value={props.options?.napsPerDay}
    onChange={(napsPerDay) => props.update({ options: { napsPerDay } })}
  />
))

const SelectNapGap = buildComponent<
  Partial<SpaceOptions> & {
    update: (options: DeepPartial<SpaceOptions>) => void
  }
>().withRender(({ props }) => (
  <UI.NumberInput
    label={
      <b style={{ fontSize: '2rem' }}>
        Forcer un temps d'attente entre deux siestes dans le même cocon
      </b>
    }
    step={5}
    value={props.options?.napDelay}
    onChange={(napDelay) =>
      props.update({
        options: { napDelay: Math.round((napDelay ?? 0) / 5) * 5 },
      })
    }
  />
))

function findActive(
  napDays: NonNullable<SpaceOptions['options']>['napDays'] | undefined,
  day: number,
) {
  if (!napDays) {
    return
  }

  while (day) {
    day -= 1
    if (napDays[dayKeys[day]]) {
      return napDays[dayKeys[day]]
    }
  }
}

const SelectOpeningHours = buildComponent<
  Partial<SpaceOptions> & {
    update: (options: DeepPartial<SpaceOptions>) => void
  }
>().withRender(({ props }) => (
  <UI.Input.Wrapper
    label={
      <b style={{ fontSize: '2rem' }}>
        Jours et horaires d'ouverture de l'espace
      </b>
    }
  >
    <UI.Stack mt={16}>
      {[1, 2, 3, 4, 5, 6, 7].map((day) => {
        const values = props.options?.napDays?.[dayKeys[day]]
        const active =
          values &&
          (values.startHour ?? null) !== null &&
          (values.endHour ?? null) !== null

        return (
          <UI.Card
            key={day}
            withBorder
            sx={{
              width: '100%',
              opacity: active ? '1' : '0.5',
              overflow: 'visible',
              height: '7rem',
              display: 'flex',
              alignItems: 'center',
            }}
            py={0}
          >
            <UI.Group position="apart" noWrap sx={{ width: '100%' }}>
              <UI.Checkbox
                checked={active}
                label={<b style={{ fontSize: '2rem' }}>{days[day]}</b>}
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    const prevActive = findActive(props.options?.napDays, day)

                    props.update({
                      options: {
                        napDays: {
                          [dayKeys[day]]: {
                            startHour: prevActive?.startHour ?? 0,
                            endHour: prevActive?.endHour ?? 24,
                          },
                        },
                      },
                    })
                  } else {
                    props.update({
                      options: {
                        napDays: {
                          [dayKeys[day]]: null,
                        },
                      },
                    })
                  }
                }}
              />
              {active && (
                <UI.Group noWrap>
                  <UI.Text size="sm">De</UI.Text>
                  <UI.Select
                    mt={-4}
                    styles={{
                      wrapper: { width: '16rem' },
                    }}
                    searchable
                    value={values.startHour + ''}
                    data={[...new Array(24)].flatMap((_, h) => [
                      { label: h + 'h00', value: '' + h },
                      { label: h + 'h30', value: '' + (h + 0.5) },
                    ])}
                    onChange={(value) =>
                      props.update({
                        options: {
                          napDays: {
                            [dayKeys[day]]: {
                              startHour: Number.parseFloat(value ?? '0'),
                              endHour: Math.max(
                                values.endHour ?? 0,
                                Number.parseFloat(value ?? '0') + 1,
                              ),
                            },
                          },
                        },
                      })
                    }
                  />
                  <UI.Text size="sm">à</UI.Text>
                  <UI.Select
                    mt={-4}
                    styles={{
                      wrapper: { width: '16rem' },
                    }}
                    searchable
                    value={values.endHour + ''}
                    data={[...new Array(24)].flatMap((_, h) =>
                      h
                        ? [
                            { label: h + 'h30', value: '' + (h + 0.5) },
                            { label: h + 1 + 'h00', value: '' + (h + 1) },
                          ]
                        : [{ label: h + 1 + 'h00', value: '' + (h + 1) }],
                    )}
                    onChange={(value) =>
                      props.update({
                        options: {
                          napDays: {
                            [dayKeys[day]]: {
                              startHour: Math.min(
                                values.startHour ?? 0,
                                Number.parseFloat(value ?? '0') - 1,
                              ),
                              endHour: Number.parseFloat(value ?? '0'),
                            },
                          },
                        },
                      })
                    }
                  />
                </UI.Group>
              )}
            </UI.Group>
          </UI.Card>
        )
      })}
    </UI.Stack>
  </UI.Input.Wrapper>
))

export const SectionBooking = buildComponent<{ space: string }>()
  .withLifecycle(({ props }) => {
    const {
      loading,
      data: space,
      update,
    } = useSynchronizeData(
      graphql.queries.getSpaceOptions,
      graphql.mutations.updateSpaceOptions,
      props.space,
    )

    return { space, update, loading }
  })
  .withRender(({ lifecycle }) => (
    <UI.Group spacing={64} align="start">
      <UI.Stack sx={{ flex: 1, width: '50%' }} spacing={48}>
        <SelectDurations {...lifecycle.space} update={lifecycle.update} />
        <SelectMaxNaps {...lifecycle.space} update={lifecycle.update} />
        <SelectNapGap {...lifecycle.space} update={lifecycle.update} />
      </UI.Stack>

      <UI.Stack sx={{ flex: 1, width: '50%' }}>
        <SelectOpeningHours {...lifecycle.space} update={lifecycle.update} />
      </UI.Stack>

      {lifecycle.loading && (
        <UI.Loader
          sx={{ position: 'absolute', top: '1rem', right: '1rem' }}
          size="xs"
        />
      )}
    </UI.Group>
  ))
